export default function ProgressCard({ animals }) {
  return (
      <>
      {animals.map((animal, index) => (
          <div key={index}
               className={`progressCard ${animal.isValid ? 'valid' : 'notValid'}`}
          >
              <img src={animal.imagePath} alt=""/>
              <p>{animal.name} </p>
          </div>
      ))}

      </>
  );
}