export default function CollectionCard({ encounter, key,  onEncounterSelection }) {

  return (
      <>
          <div className="collectionCard" key={key}>
              <img src={encounter.image} alt="Poisson cocher" className={"collectionPicture"}/>
              {/*<img src="img/species/fish_encounter.png" alt="Poisson cocher" className={"collectionPicture"}/>*/}
              <div className="collectionName">
                  <p>{encounter.name}</p>
              </div>
              <div className="collectionData">
                  <div>
                      <img src="img/eyes.png" alt="Date rencontre"/>
                      <p>{encounter.date}</p>
                  </div>

                  <img src="img/see_more.png" alt="Plus d'informations"
                  onClick={() => onEncounterSelection(encounter)}/>
              </div>
          </div>

      </>
  );
}