import React, {useEffect, useState} from "react";
import ShapePictureCard from "../../components/shape_picture_card";
import Confetti from "../../components/confetti";
import SuccessCard from "../../components/success_card";
import ProgressCardShape from "../../components/progress_card_shape";
import Webcam from "react-webcam";

export default function Cycle1() {
    const [step, setStep] = useState(0);
    const [isValid, setIsValid] = useState('');
    const [isConfetti, setIsConfetti] = useState(false);

    const [isScan, setIsScan] = useState(false);
    const [selectedSpecies, setSelectedSpecies] = useState({});

    const [speciesList, setSpeciesList] = useState( [
        { name: 'Mérou', imagePath: 'img/species/grouper.png', isValid: false },
        { name: 'Poulpe', imagePath: 'img/species/octopus.png', isValid: false },
        { name: 'Murène Commune', imagePath: 'img/species/eel.png', isValid: false},
        { name: 'Barracuda', imagePath: 'img/species/barracuda.png', isValid: false}
    ])
    const webcamRef = React.useRef(null)
    const videoConstraints = {
      width: 350,
      height: 300,
      facingMode: { exact: "environment" },
      forceScreenshotSourceSize:"true"
    }
    const incrementStep = () => {
        setStep(step + 1)
    }

    const decrementStep = () => {
        setStep(step - 1)
    }
    const toggleValid = () => {
        setIsValid(!isValid)
    }

    useEffect(() => {
        if(isValid) {
            Confetti()
        }
    }, [isValid]);


    const handleToggleConfetti = (state) => {
        setIsConfetti(state)
    }
    /**
     * Match a given species' name against our species list, then declare it valid
     * @param name
     */
    const validateSpecies = (name) => {
        setSpeciesList(speciesList.map(species =>
            species.name === name
            ? { ...species, isValid: true }
            : species
    ));
    }

    const handleSpeciesDetection = (detectedSpecies) => {
    if (selectedSpecies.name === detectedSpecies) {
        setIsValid(true);
        setIsConfetti(true)
        validateSpecies(detectedSpecies);
    } else {
        setIsValid(false);
        }
    }

    const handleSpeciesSelection = (species) => {
        setSelectedSpecies(species)
        incrementStep()
    }
    const handleStepDecrement = () => {
        setIsConfetti(false)
        decrementStep()
    }
    const handleStepIncrement = () => {
        setIsConfetti(false)
        incrementStep()
    }

    const lastStep = () => {
        Confetti()
        setIsConfetti(true)
        setStep(2)
    }
  return (
      <>
          <canvas id="confetti" className={isConfetti ? "display" : "hide"}></canvas>

          {step === 0 ?
              <div id="progressWrap">

                  <ProgressCardShape
                      animals={speciesList}
                      onSpeciesSelection={handleSpeciesSelection}
                      selectedSpecies={selectedSpecies}
                  />
                  <button onClick={lastStep} className={"nextButton"}>
                      TERMINER
                      <img src="img/arrow.png" alt="Lancer"/>
                  </button>
              </div>
              : null
          }

          {step === 1 ?
              <div id="teamSelection">

                  <ShapePictureCard
                      selectedSpecies={selectedSpecies}
                      onSpeciesDetection={handleSpeciesDetection}
                      toggleConfetti={handleToggleConfetti}
                      isValid={isValid}
                      onDecrementStep={handleStepDecrement}
                      onIncrementStep={handleStepIncrement}
                  />

              </div>
              : null
          }

          {step === 2 ?
              <div id={"successWrap"}>
                  <img src="img/reef.png" id={"endBackground"}/>
                  <SuccessCard/>
              </div>
              : null
          }

          <div id="authWebcam">
              <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  screenshotQuality={1}
                  videoConstraints={videoConstraints}
              />
          </div>
      </>
  );
}