import React, {useState} from "react";
import PictureTaker from "./picture_taker";

/**
 * Use the phone camera to take a picture, returns the species detected
 * @returns {JSX.Element}
 * @constructor
 */
export default function PictureCard({teamImage, scansCount, isValid, isScan, onSpeciesDetection, onNewCapture}) {
    const [species, setSpecies] = useState('');
    const [trigger, setTrigger] = useState(0);
    const [reset, setReset] = useState(0);
    const handleSpeciesDetection = (result) => {
        onSpeciesDetection(result)
        setSpecies(result)
    }

    const triggerPicture = () => {
        setTrigger(trigger + 1)
    }

    const initNewCapture = () => {
        onNewCapture()
        setReset(reset + 1)
        setSpecies('')
    }

    return (
    <>
        <div id="captureCard">

            <div id="stats">
                <div id="leftStat">
                    <p id={"timer"}>0:18</p>
                    <img src={teamImage} alt=""/>
                </div>
                <div id="rightStat">
                    <p>{scansCount} {scansCount < 1 ? 'scan' : 'scans'}</p>
                </div>

            </div>
            {species}
            <PictureTaker
                onSpeciesDetection={handleSpeciesDetection}
                trigger={trigger}
                reset={reset}
            />

            {isScan ?
                <div id="scanValidation" className={isValid === true ? "validScan" : "notValidScan"}></div>
                : null
            }

        </div>
        {isScan ?
            <div id="logoCapture"
                 className={isValid ? "validButton" : "notValidButton"}
                onClick={initNewCapture}>
                <img src={isValid && isScan ? "img/checkmark.png" : "img/cross1.png"} alt=""/>
            </div>
            : <div id="logoCapture">
                <button
                    onClick={(e) => {
                        e.preventDefault()
                        triggerPicture()
                    }}
                    id="triggerButton">
                    <img src="img/camera_outline.png" alt=""/>
                </button>
              </div>
        }

    </>
    );
}