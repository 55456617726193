import {Link} from "react-router-dom";
import {useState} from "react";

export default function SpeciesInformation({ encounter, onSpeciesClose }) {
    const [isSimpleDisplay, setIsSimpleDisplay] = useState(true);

    const setDisplayType = (value) => {
      setIsSimpleDisplay(value)
    }
  return (
      <>
          <div>
              <div id="headerSpecies">
                  <div id={"closeSpecies"}
                       onClick={() => onSpeciesClose()}>
                      <img src="img/cross.png" alt=""/>
                  </div>
                  <img src={encounter.image} alt=""/>
                  <div id="menuSpecies">
                      <div onClick={() => setIsSimpleDisplay(true)}>Résumé <div id="status" className={isSimpleDisplay ? "activeDisplay" : ""}></div></div>
                      <div onClick={() => setIsSimpleDisplay(false)}>Avancé <div id="status" className={isSimpleDisplay ? "" : "activeDisplay"}></div></div>
                  </div>
              </div>


              <div id="speciesWrap">
                  <div id="speciesName">
                      <p>POISSON COCHER COMMUN</p>
                      <p>Heniochus acuminatus</p>
                  </div>
                  <div id="speciesDataContainer">

                      {isSimpleDisplay ?

                          <div id="pictoContainer">
                              <div><img src="img/size.png" alt=""/>
                                  <p>{encounter.size}</p>
                              </div>
                              <div><img src="img/max_size.png" alt=""/>
                                  <p>{encounter.maxSize}</p>
                              </div>
                              <div><img src="img/age.png" alt=""/>
                                  <p>{encounter.longevity}</p>
                              </div>
                              <div><img src="img/pattern.png" alt=""/>
                                  <p>{encounter.motif}</p>
                              </div>
                          </div>
                          : <div id="advancedContainer">
                              <div id="speciesText">
                                  {encounter.description}
                              </div>
                              <div id="pictoContainer">
                                  <div><img src="img/size.png" alt=""/>
                                      <p>{encounter.size}</p>
                                  </div>
                                  <div><img src="img/max_size.png" alt=""/>
                                      <p>{encounter.maxSize}</p>
                                  </div>
                                  <div><img src="img/age.png" alt=""/>
                                      <p>{encounter.longevity}</p>
                                  </div>
                                  <div><img src="img/pattern.png" alt=""/>
                                      <p>{encounter.motif}</p>
                                  </div>
                              </div>
                          </div>
                      }


                      {/* Avancé */}

                  </div>

              </div>
          </div>

      </>
  );
}