export default function ProgressCardShape({ animals, onSpeciesSelection, selectedSpecies }) {

    const handleSpeciesSelection = (animal) => {
        onSpeciesSelection(animal)
    }

  return (
      <>
      {animals.map((animal, index) => (
          <div key={index}
               className={`progressCard ${animal.isValid ? "validProgress" : ""}`}
               onClick={() => handleSpeciesSelection(animal)}
          >
              <img src={animal.imagePath} alt="" className={`${animal.isValid ? '' : 'obfuscate'}`}/>
              {animal.isValid ?
                  <p>{animal.name} </p>
                  : null
              }
          </div>
      ))}

      </>
  );
}