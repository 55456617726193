import {useState} from "react";

export default function TeamChoice({ teams, onTeamClick }) {
 const [selectedTeam, setSelectedTeam] = useState({});
 const handleTeamClick = (team) => {
    setSelectedTeam(team);
    onTeamClick(team)
 };

  return (
      <>
          {teams.map((team, index) => (
              <div
                   key={index}
                   className={`teamCard ${selectedTeam.name === team.name ? 'selected' : ''}`}
                   onClick={() => {
                       handleTeamClick(team);
                   }}
              >
                  <img src={team.imagePath} alt=""/>
                  <p>TEAM <br/><span className='teamName'> {team.name} </span></p>
              </div>
          ))}

    </>
  );
}