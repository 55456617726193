export default function Cycle3() {
  return (
    <>
      <div id="sidebar">

        <h2>Profile</h2>
      </div>
      <div id="detail"></div>
    </>
  );
}