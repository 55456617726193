import {Link} from "react-router-dom";

export default function SuccessCard() {
  return (
    <>
      <div id="successCard">
          <Link to="/" id={"successClose"}> <img src="img/cross_black.png" alt="Retour accueil"/></Link>
          <div id="clap">
              <p>👏👏👏</p>
        </div>
          <div id="successImage">
              <img src="img/round_octopus.png" alt=""/>
          </div>
          <p>Bravo ! 🎉</p>
      </div>

    </>
  );
}