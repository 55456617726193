import axios from "axios";
import {useState} from "react";

export default function ApiTest() {

// On prépare la variable d'état 'results' pour accueillir le résultat de l'api.
// Dès qu'on la modifie avec un 'setResults', la vue se recharge pour mettre les nouvelles données
const [results, setResults] = useState({});


  function getList() {
   axios
      .get("http://localhost:8000/api/apis-list/")
      .then((res) => {
          console.log(res.data)
          setResults(res.data);
      })
      .catch((err) => console.log(err));
}

  return (

      <div className="App">
          <header className="App-header">

              {/*Plus tu cliques fort sur le bouton, plus les données sont de bonne qualité*/}
              <button onClick={() => getList()}>Get api list</button>

              {/*Affichage du résultat de la requête, qui est sous forme d'objet*/}
              {Object.keys(results).map(function(key) { return <div>Key: {key}, Value: {results[key]}</div>; })}

          </header>
      </div>
  );
}