import {Link} from "react-router-dom";

export default function Profile() {
  return (
    <>

      <div id="profileContainer">
        <div id="profileHeader" style={{
          backgroundImage: "url(img/top_background.png)",
        }}>
          {/*<img src="img/top_background.png" alt=""/>*/}
          <div id="actions">
            <div>
              <img src="img/pencil.png" alt="Modifier profil"/>
            </div>
            <div>
              <Link to="/"> <img src="img/cross1.png" alt="Accueil"/></Link>

            </div>
          </div>
          <div id="profileImage">
            <img src="img/profile.png" alt=""/>
          </div>
        </div>

        <div id="profileBody">
          <div id="profileInfo">
            <p id={"name"}>Paul</p>
            <p id={"title"}>Octopus curieux</p>
            <p id={"level"}>Niveau 42</p>
          </div>

          <div id="highlightsContainer">
            <div id={"leftHighlight"}>
              <img src="img/camera.png" alt=""/>
              <p className={"statNumber"}>228</p>
              <p className={"statName"}>rencontres</p>
            </div>
            <div id={"middleHighlight"}>
              <img src="img/octo.png" alt=""/>
              <p className={"statNumber"}>15</p>
              <p className={"statName"}>espèces</p>
            </div>
            <div id={"rightHighlight"}>
              <img src="img/medal.png" alt=""/>
              <p className={"statNumber"}>7</p>
              <p className={"statName"}>succès</p>
            </div>
          </div>

          <div id="achievementsWrap">
            <p>Succès <span id={"achievementCount"}> 2/35</span></p>
            <div id="achievementsContainer">
              <img src="img/octo.png" alt="" className={"achievementUnlocked"}/>
              <img src="img/jump.png" alt="" className={"achievementUnlocked"}/>
              <img src="img/squirrel.png" alt=""/>
              <img src="img/question.png" alt=""/>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}