import React, {useState} from "react";
import PictureTaker from "./picture_taker";
import {step} from "@tensorflow/tfjs";

export default function ShapePictureCard({selectedSpecies, isValid, isScan, toggleConfetti, onSpeciesDetection, onIncrementStep, onDecrementStep}) {
    const [species, setSpecies] = useState('');
    const [trigger, setTrigger] = useState(0);
    const [reset, setReset] = useState(0);
    const [stepName, setStepName] = useState("shape")

    const handleSpeciesDetection = (result) => {
        setStepName("result")
        onSpeciesDetection(result)
        setSpecies(result)
    }

    const triggerPicture = () => {
        setTrigger(trigger + 1)
    }

    const initNewCapture = () => {
        setStepName("capture")
        setReset(reset + 1)
        setSpecies('')
        toggleConfetti(false)
    }
    const handleNewStep = (step) => {
        setStepName(step)
    }

    const decrementStep = () => {
        onDecrementStep()
    }
    const incrementStep = () => {
        onIncrementStep()
    }


  return (
      <>
          <div id="captureCard" className={stepName === "shape" ? "shapeCard" : ""}>

              {stepName === "shape" ?
                  <img src={selectedSpecies.imagePath} alt="" className={isValid ? "" : "obfuscate"} id={"shapeImage"}/>
                  : null
              }
              {stepName === "capture" || stepName === "result" ?
                  <span>
                    {species}
                      <PictureTaker
                          onSpeciesDetection={handleSpeciesDetection}
                          trigger={trigger}
                          reset={reset}
                      />
                </span>

                  : null
              }
              {stepName === "result" ?
                  <div id="scanValidation" className={isValid === true ? "validScan" : "notValidScan"}></div>
                  : null
              }


          </div>

            {stepName === "shape" ?
              <div id="logoCapture">
                  <div id="return" onClick={decrementStep}>
                      <img src="img/arrow.png" alt="Retour"/>
                  </div>
                  <button
                      onClick={() => handleNewStep('capture')}
                      id="triggerButton">
                      <img src="img/camera_outline.png" alt=""/>
                  </button>
              </div>
              : null
          }
          {stepName === "capture" ?
              <div>
                  <div id="logoCapture">
                      <div id="return" onClick={decrementStep}>
                          <img src="img/arrow.png" alt="Retour"/>
                      </div>
                      <button
                          onClick={(e) => {
                              e.preventDefault()
                              triggerPicture()
                          }}
                          id="triggerButton">
                          <img src="img/camera_outline.png" alt=""/>
                      </button>
                  </div>
              </div>
              : null
          }
          {stepName === "result" ?
              <span>
                <div id="logoCapture"
                     className={isValid ? "validButton" : "notValidButton"}>
                    <div id="return" onClick={decrementStep}>
                      <img src="img/arrow.png" alt="Retour"/>
                  </div>
                    <img src={isValid && isScan ? "img/checkmark.png" : "img/cross1.png"} alt=""
                         onClick={initNewCapture}/>
                </div>
            </span>
              : null
          }

      </>
  );
}