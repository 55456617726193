import React, {useEffect, useRef, useState} from 'react'
import Webcam from 'react-webcam'
import * as tf from "@tensorflow/tfjs";
import Model from "../model";
const videoConstraints = {
  width: 350,
  height: 300,
  facingMode: { exact: "environment" },
  forceScreenshotSourceSize:"true"
}
export default function PictureTaker({ onSpeciesDetection, trigger, reset}) {
    const [picture, setPicture] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const webcamRef = React.useRef(null)

    useEffect(() => {
        if (trigger) {
          capture();
        }
    }, [trigger]);

    useEffect(() => {
        if (reset) {
          setPicture('')
        }
    }, [reset]);


    const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot()
        setPicture(pictureSrc)
        handlePictureTaken(pictureSrc)
    })

    const handlePictureTaken = (pictureTaken) => {
        convertBase64ToTensor(pictureTaken).then(tensor => {
            callModel(tensor)
        });
    }
    async function convertBase64ToTensor(base64String) {
        const image = await loadImage(base64String);
        return tf.browser.fromPixels(image);
    }
    function loadImage(base64String) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = base64String;
        });
    }

    function callModel(tensor) {
        setIsLoading(true)
        const resized = tf.image.resizeBilinear(tensor, [200, 200]).toFloat();
        const batched = resized.expandDims(0)
        Model(batched)
          .then(animal => {
            onSpeciesDetection(animal)
            setIsLoading(false)
          })
          .catch(error => {
              console.error(error);
          });
    }


    const canvasRef = useRef(null);

  return (
    <div>
      <div id={"cameraScreen"}>
        {picture == '' ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            screenshotQuality={1}
            videoConstraints={videoConstraints}
          />
        ) : (
          <img src={picture} id={"pictureResult"}/>
        )}
      </div>
      <div>

            {/*  TODO Put this logic into the logoCapture button*/}
            {/*{picture != '' ? (*/}
        {/*  <button*/}
        {/*    onClick={(e) => {*/}
        {/*      e.preventDefault()*/}
        {/*      setPicture()*/}
        {/*    }}*/}
        {/*    className="btn btn-primary"*/}
        {/*  >*/}
        {/*    Retake*/}
        {/*  </button>*/}
        {/*) : (*/}
        {/*  <button*/}
        {/*    onClick={(e) => {*/}
        {/*      e.preventDefault()*/}
        {/*      capture()*/}
        {/*    }}*/}
        {/*    className="btn btn-danger"*/}
        {/*  >*/}
        {/*    Capture*/}
        {/*  </button>*/}
        {/*)}*/}
      </div>
          {isLoading ?
              <div id="circleBackground">
                  <div className="circle">
                      <div className="wave"></div>
                  </div>
              </div>
              : null
          }
    </div>
  )
}
