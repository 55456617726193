import CollectionCard from "../components/collection_card";
import {useState} from "react";
import SpeciesInformation from "../components/species_information";
import {Link} from "react-router-dom";

export default function Collection() {
    const [selectedSpecies, setSelectedSpecies] = useState({});
    const encountersNice = [
    {name: "Murène", date:"10/01/2024", event : "Musée naturel de Nice", image: "img/collection/eel.jpg",size: "100cm", maxSize: "150cm", longevity: "15 ans", motif: "Tâches", id:1,
    description: "Mureana signifie murène en latin. Ces dernières, cousines des congres, sont caractérisées par un corps allongé, légèrement comprimé latéralement. La peau, sans écailles, est lisse et recouverte d'un mucus. La tête, plus petite que le corps, présente généralement une large gueule munie de dents tranchantes.\n" +
        "Les nageoires pelviennes et ventrale sont absentes et les nageoires dorsale et caudale sont fusionnées en une longue bande allant de la tête à la queue.\n" +
        "Bien qu'ayant une mauvaise réputation, les murènes ne sont en réalité agressives que lorsqu'elle ressent une menace. Il n'est pas rare de voire des plongeurs, fréquentant régulièrement les mêmes sites de plongée, manipuler des murènes habituées à la présence humaine."}
    ]
const encountersToulon = [
    {name: "Mérou", date:"27/02/2024", event : "Musée de la Marine Toulon", image: "img/collection/grouper.jpg",size: "120cm", maxSize: "140cm", longevity: "50 ans", motif: "Marbrures", id:2,
    description: "Ce sont des carnassiers territoriaux au corps allongé et robuste. La tête est imposante, la mâchoire contient des canines sur l'avant. La bouche est protractile. Toutes les espèces possèdent 10 ou 11 épines dorsales.\n" +
        "La plupart des espèces se rencontrent dans les récifs coralliens ou rocheux à quelques exceptions près qui vivent dans les fonds sableux, limoneux ou boueux. Les adultes sont généralement observés entre 10 et 200 mètres de profondeur. La majorité des Epinephelus sont reconnus comme hermaphrodites protogynes, c'est-à-dire qui naissent femelles et deviennent mâles en grandissant. Il se pourrait cependant que toutes les femelles ne changent pas de sexe et que certains mâles ne passent pas par ce stade communément admis.\n" +
        "Ces poissons, très prisés commercialement pour la consommation et la pêche récréative, sont vulnérables à la surpêche en raison de leur croissance lente, de leur maturité tardive et de la formation d'agrégations de reproduction. La surpêche, particulièrement ciblée sur les individus matures, peut entraîner des déséquilibres de sex-ratio et affecter la reproduction. Plusieurs mérous font à présent l'objet de fermes d'aquaculture.\n" +
        "Selon l'IUCN, la surpêche et la pollution ont déjà entraîné une chute importante des populations de nombreux mérous et plusieurs espèces sont en danger d'extinction."},
    {name: "Poulpe", date:"01/03/2024", event : "Musée de la Marine Toulon", image: "img/collection/poulpe.jpg",size: "60cm", maxSize: "120", longevity: "1 an", motif: "Marbrures", id:3,
    description: "Les espèces du genre Octopus sont communément appelées pieuvres ou poulpe. Octopus signifie 8 pieds en grec, en référence aux huit bras de la pieuvre. Les espèces de ce genre se rencontrent dans presque toutes les mers et océans du monde. Comme l'ensemble des céphalopodes, les Octopus possèdent une toxine appelée cephalotoxine. Cette neurotoxine est produite par deux glandes salivaires en arrière du bec. Elle sert à se défendre ou à paralyser leur proie."},
    {name: "Poulpe", date:"05/03/2024", event : "Musée de la Marine Toulon", image: "img/collection/poulpe_2.jpg",size: "60cm", maxSize: "120", longevity: "1 an", motif: "Marbrures", id:4,
    description: "Toutes les espèces possèdent une tête de forme ovale. La coloration peut varier en fonction de l'environnement et du contexte. En effet, ces animaux sont capables de changer de couleur pour échapper à des prédateurs ou chasser. Le cycle de vie est court, et comme chez la plupart des céphalopodes, les femelles meurent à la fin du cycle de reproduction."}
]

    const handleEncounterSelection = (animal) => {
        setSelectedSpecies(animal)
    }
    const resetSpecies = () => {
        setSelectedSpecies({})
    }
  return (
    <>

      <div id="collectionBody">

        <div id="filterMenu">
          <input type="text"/>
            <Link to={"/"}> <img src="img/home.png" alt=""/></Link>

        </div>

          <div className="encounterEvent">
            <h2>Musée naturel de Nice ({encountersNice.length})</h2>
            <div id="collectionContainer">
                {encountersNice.map((encounter, index) => (
                    <CollectionCard
                        encounter={encounter}
                        key={index}
                        onEncounterSelection={() => handleEncounterSelection(encounter)}
                    />
                ))}
            </div>
        </div>
        <div className="encounterEvent">
            <h2>Musée de la Marine Toulon ({encountersToulon.length})</h2>
            <div id="collectionContainer">
                {encountersToulon.map((encounter, index) => (
                    <CollectionCard
                        encounter={encounter}
                        key={index}
                        onEncounterSelection={() => handleEncounterSelection(encounter)}
                    />
                ))}
            </div>
        </div>

      </div>


        {Object.keys(selectedSpecies).length !== 0 ?
            <div id="speciesPopup">
                <SpeciesInformation
                    encounter={selectedSpecies}
                    onSpeciesClose={resetSpecies}
                />
            </div>

            : null
        }

    </>
  );
}