import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home from "./routes/home";
import Profile from "./routes/profile";
import Cycle1 from "./routes/quests/cycle_1";
import Cycle2 from "./routes/quests/cycle_2";
import Cycle3 from "./routes/quests/cycle_3";
import ApiTest from "./routes/tests/api_test";
import Collection from "./routes/collection";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/cycle-1",
    element: <Cycle1 />,
  },
  {
    path: "/cycle-2",
    element: <Cycle2 />,
  },
  {
    path: "/cycle-3",
    element: <Cycle3 />,
  },
  {
    path: "/api-test",
    element: <ApiTest />,
  },
  {
    path: "/collection",
    element: <Collection />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>

      <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
