import TeamChoice from "../../components/team_choice";
import '../../styles/style.scss'
import React, {useEffect, useState} from "react";
import PictureCard from "../../components/picture_card";
import ProgressCard from "../../components/progress_card";
import SuccessCard from "../../components/success_card";
import Webcam from "react-webcam";

export default function Cycle2() {
    const [step, setStep] = useState(0);
    const [selectedTeam, setSelectedTeam] = useState({});
    const [scanCount, setScanCount] = useState(0)
    const [isValid, setIsValid] = useState(false);
    const [isScan, setIsScan] = useState(false);
    const webcamRef = React.useRef(null)
    const videoConstraints = {
  width: 350,
  height: 300,
  facingMode: { exact: "environment" },
  forceScreenshotSourceSize:"true"
}
    const teams = [
         { name: 'Animaux', imagePath: 'img/penguin.png' },
         { name: 'Menaces', imagePath: 'img/bone.png' },
    ];

    const [speciesList, setSpeciesList] = useState( [
        { name: 'Mérou', imagePath: 'img/species/grouper.png', isValid: false },
        { name: 'Poulpe', imagePath: 'img/species/octopus.png', isValid: false },
        { name: 'Murène Commune', imagePath: 'img/species/eel.png', isValid: false},
        { name: 'Barracuda', imagePath: 'img/species/barracuda.png', isValid: false}
    ])

    /**
     * Match a given species' name against our species list, then declare it valid
     * @param name
     */
    const validateSpecies = (name) => {
        setSpeciesList(speciesList.map(species =>
            species.name === name
            ? { ...species, isValid: true }
            : species
    ));
    }

    /**
     * Every time speciesList is updated, count how many entries are valid
     */
    useEffect(() => {
        const count = speciesList.filter(item => item.isValid).length;
        setScanCount(count);
     }, [speciesList]);

    const incrementStep = () => {
        setStep(step + 1)
    }
    const decrementStep = () => {
        setStep(step - 1)
    }
    const incrementScanCount = () => {
        setScanCount(scanCount + 1)
    }
    const handleTeamClick = (newTeam) => {
        setSelectedTeam(newTeam)
    }

    const handleSpeciesDetection = (detectedSpecies) => {
        const foundSpecies = speciesList.find(species => species.name === detectedSpecies);
        if (foundSpecies) {
            setIsValid(true);
            setIsScan(true);
            validateSpecies(detectedSpecies);
        } else {
            setIsValid(false);
            setIsScan(true);
            }
    }

    const handleNewCapture = () => {
        setIsScan(false)
    }

  return (
    <>
        {step === 0 ?
            <div id="teamSelection">
                <h1>Choisis ta TEAM</h1>
                <TeamChoice
                    teams={teams}
                    onTeamClick={handleTeamClick}
                />
                <button onClick={incrementStep} className={"nextButton"}>LANCER <img src="img/arrow.png" alt="Lancer"/></button>
            </div>
            : null
        }
        {step === 1 ?
            <div id="captureWrap">
                <PictureCard
                    teamImage={selectedTeam.imagePath}
                    scansCount={scanCount}
                    onSpeciesDetection={handleSpeciesDetection}
                    onNewCapture={handleNewCapture}
                    isValid={isValid}
                    isScan={isScan}
                />

                <button className={"nextButton test"} onClick={incrementStep}><img src="img/arrow.png" alt="Lancer"/></button>
                {/*{isScan ?*/}
                {/*    <div onClick={incrementStep} id="logoCapture"*/}
                {/*         className={isValid ? "validButton" : "notValidButton"}>*/}
                {/*        <img src={isValid && isScan ? "img/checkmark.png" : "img/cross1.png"} alt=""/>*/}
                {/*    </div>*/}
                {/*    : <div onClick={incrementStep} id="logoCapture">*/}
                {/*      <img src="img/camera_outline.png" alt=""/>*/}
                {/*  </div>*/}
                {/*}>*/}

            </div>
            : null
        }
        {step === 2 ?
            <div id="progressWrap">
                <ProgressCard
                    animals={speciesList}
                />

                <button onClick={incrementStep} className={"nextButton"}>
                    TERMINER
                    <img src="img/arrow.png" alt="Lancer"/>
                </button>
            </div>
            : null
        }

        {step === 3 ?
            <div id={"successWrap"}>
                <img src="img/reef.png" id={"endBackground"}/>
                <div id="endPopup">
                    <h2>Bien joué !</h2>
                    <p>Ton herbier a {scanCount}/{speciesList.length} animaux et est protégé de 4/5 menaces</p>
                    <button onClick={incrementStep} className={"nextButton"}>
                        <img src="img/arrow.png" alt="Lancer"/>
                    </button>
                </div>
            </div>
            : null
        }
        {step === 4 ?
            <div id={"successWrap"}>
                <img src="img/reef.png" id={"endBackground"}/>
                <SuccessCard />
            </div>
            : null
        }

        {/*Add a webcam component to trigger the authorization popup on startup*/}
        <div id="authWebcam">
            <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                screenshotQuality={1}
                videoConstraints={videoConstraints}
          />
        </div>

    </>
  );
}