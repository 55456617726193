import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";

export default function Home() {
    const navigate = useNavigate();
    const [pin, setPin] = useState('');

    const handlePinChange = (event) => {
        setPin(event.target.value)
    }
    const submitPin = () => {
        if(pin === "1") {
            navigate("/cycle-1")
        }
        if(pin === "2") {
            navigate("/cycle-2")
        }
        if(pin === "3") {
            navigate("/cycle-3")
        }
    }

  return (

      <div id="home">
          
          <div id="hero">
              <img src="img/logo_full.png" alt=""/>
              <p>Ceresia</p>
          </div>
          <div id="pinInput">
              <input id="pin" type="text" placeholder={"Entrer le code PIN"}
                     onChange={handlePinChange}/>
          </div>
          <p onClick={submitPin} id={"submitPin"}>Valider</p>
          <div id="homeBottomMenu">
              <Link to="collection"><img src="img/image.png" alt="Collection"/></Link>
              <Link to="profile"> <img src="img/avatar.png" alt="Profil"/></Link>
          </div>

      </div>
  );
}